<template>
  <div>
    <b-card class="p-0 filter-card rounded-lg">
      <b-row>
        <b-col cols="12">
          <h3 class="ml-1 mb-2">Filters</h3>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.school_id"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="schools"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="School"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
            :disabled="!hasPermission('Filter By School Users')"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.type_id"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="types"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="User Role"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.status"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="status_data"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="User Status"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="4" class="mt-2">
          <b-form-input 
            v-model="filter.keyword"
            placeholder="Search name or email"
          />
        </b-col>
      </b-row>
      
    </b-card>
    <b-card class="w-100 d-flex rounded-lg">
      <b-row>
        <b-col md="12" class="pb-2 w-100 d-flex justify-content-between">
          <h3 class="ml-1 mb-2">Users</h3>
          <div>
            <b-button
              class="btn rounded-pill ml-auto"
              variant="primary"
              @click="exportUsers()"
            >
              Export Users
            </b-button>
            <b-button
              class="btn rounded-pill ml-1"
              variant="primary"
              @click="openInvitation()"
            >
              User Invitation
            </b-button>
          </div>
        </b-col>
      </b-row>
      <center v-if="load">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </center>
      <div v-else >
        <b-table :items="items.data" :fields="fields" striped responsive @sort-changed="onSortChange">
        <template #filter> </template>
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.full_name }}
              </b-col>
              <b-col md="4" class="mb-1"> </b-col>
              <b-col md="4" class="mb-1">
                <strong>Email : </strong>{{ row.item.email }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col md="4" class="mb-1">
                
                <strong>Age : </strong>{{ row.item.age }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.item.avatar" />
        </template>

        <template #cell(fname)="data">
          <div>
            {{ data.item.fname + " " + data.item.lname }}
          </div>
        </template>

        <template #cell(role_name[0])="data">
          <b-badge :variant="status[1][data.item.roles[0]? data.item.roles[0].id :0]"  v-b-tooltip.hover.top="joinRoles(data.item.role_name)">
            {{data.item.role_name[0]}} 
          </b-badge> 
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <div v-if="hasPermission('Active/Inactive Users')">
              <feather-icon v-if="!data.item.status" icon="UserXIcon" @click="switchStatus(data.item)" class="mr-1 text-dark" size="20" v-b-tooltip.hover.top="'Inactive user'" />
              <feather-icon v-else icon="UserCheckIcon"  @click="switchStatus(data.item)" class="mr-1 text-primary" size="20" v-b-tooltip.hover.top="'Active user'" />
            </div>
            <!-- <b-form-checkbox v-if="hasPermission('Active/Inactive Users')"
              @change="switchStatus(data.item)"
              :checked="checkboxStatus[data.item.id]"
              name="check-button"
              switch
              inline
            >
            </b-form-checkbox> -->
            <!-- <feather-icon class="font-weight-bolder" icon="KeyIcon" size="18"/> -->
            <b-link
              class="alert-link cutom-alert-link"
              @click="assignRole(data.item)"
            >
              <feather-icon icon="KeyIcon" size="20" v-b-tooltip.hover.top="'Assign Role'" />
            </b-link>
            <!-- <button class="btn btn-primary rounded-pill" @click="assignRole(data.item)">Assign Role</button> -->
          </div>
        </template>
      </b-table>
      
      <b-pagination 
        align="center"
        v-model="items.current_page"
        :total-rows="items.total"
        :per-page="items.per_page"
        @change="onPageChange"
      ></b-pagination>
      </div>
    </b-card>
    <b-modal
      id="modal-1"
      title="User Invitation"
      hide-footer
      v-model="userInvitationModal"
    >
      <b-card-text>
        <SendInvitations @uploaded="userInvitationModal=false"/>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import SendInvitations from "@/views/modules/admins/SendInvitations.vue";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    ToastificationContent,
    SendInvitations,
    BCardText,
    vSelect,
    BLink,
    BSpinner,
    BPagination,
    BFormInput,
  },
  computed: {
    ...mapGetters({
      items: "users/items",
      schools: "users/schools",
      types: "users/types",
      load: "users/load",
    }),
    school_id_param() {
      return this.$route.query.school_id
        ? { school_id: this.$route.query.school_id }
        : null;
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  watch: {
    checked(newVal) {
      if (newVal) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Form Submitted",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
    },
    "filter.school_id"(newVal) {
      this.$router.push({name:'users', query: { school_id: this.filter.school_id}})
      this.$store.dispatch("users/users", { query: this.filter });
    },
    "filter.type_id"(newVal) {
      this.$store.dispatch("users/users", { query: this.filter });
    },
    "filter.status"(newVal) {
      this.$store.dispatch("users/users", { query: this.filter });
    },
    "filter.keyword"(newVal) {
      newVal == "" ? this.filter.keyword = null : this.filter.keyword = newVal
      this.$store.dispatch("users/users", { query: this.filter });
    },
  },
  data() {
    return {
      checkboxStatus: {},
      currentPage: 1,
      perPage: 10,
      totalRows: 0, 
      checked: true,
      userInvitationModal: false,
      filter: {
        school_id: null,
        type_id: null,
        status: null,
        keyword:null
      },
      status_data:[
        {id:'1', name:'Active'},
        {id:'0', name:'Inactive'},
      ],
      fields: [
        // "show_details",
        // { key: "avatar", label: "Avatar" },
        { key: "fname", label: "Name" ,sortable: true},
        { key: "email", label: "Email" ,sortable: true},
        { key: "school_name", label: "School" ,sortable: true},
        { key: "phone", label: "Phone Number" },
        { key: "birthdate", label: "Date of Birth" },
        { key: "role_name[0]", label: "Role" ,sortable: true },
        "Actions",
      ],
      status: [
        {
          1: "Admin",
          2: "Teacher",
          3: "Head of Department",
          5: "Student",
        },
        {
          "1": "light-primary",
          "2": "light-success",
          "3": "light-danger",
          "4": "light-warning",
          "5": "light-info",
        },
      ],
    };
  },
  methods: {
    onSortChange(ctx) {
    },
    onPageChange(page) {
      this.$store
          .dispatch("users/users", { query: {...this.filter,page:page,} })
          .then((_) => {
            this.filter.school_id = this.schools
              ? this.school_id_param.school_id
              : null;
              
          });
          // this.currentPage = this.items.current_page
    },
    refreshData(){
      
      this.$store.dispatch("users/users", { query: {...this.filter,page:this.items.current_page,} });
    },
    joinRoles(roles) {
      return roles.join(', ')
    },
    init() {
      this.$store.dispatch("users/schoolLookups").then((_) => {
        if (this.school_id_param) {
          this.filter.school_id = this.school_id_param.school_id;
        }
        this.$store
          .dispatch("users/users", { query: {...this.filter,page:1,} })
          .then((_) => {
            this.filter.school_id = this.schools
              ? this.school_id_param.school_id
              : null;
              
          });
          
      });
      // this.currentPage = this.items.current_page
      this.perPage = this.items.per_page
      this.totalRows = this.items.total
    },
    assignRole(item) {
      this.$router.push({ name: "assign_role", params: { id: item.id } });
    },
    switchStatus(item) {
      let _this = this;
      let previousStatus = this.checkboxStatus[item.id];
      let arrList = this.checkboxStatus;
      this.$swal({
        icon: "warning",
        title: `Are you sure?`,
        showCancelButton: true,
        confirmButtonText: "Yes, I am sure!",
        cancelButtonText: "No, cancel it!",
        confirmButtonColor: "#E84185",
      }).then(function (isConfirm) {
        if (isConfirm.isConfirmed) {
          _this.$store.dispatch("users/inactiveUser", item.id).then((_) =>{
              _this.refreshData();
            })
          //   if(item.status == 1){
          //     _this.$store.dispatch('schools/inactiveSchool', item.id)
          //   }else{
          //     _this.$store.dispatch('schools/activeSchool', item.id)
          //   }
        } else {
          // if (item.status == true) {
          //   _this.model = true;
          // } else {
          //   _this.model = false;
          // }
          _this.$set(arrList, item.id, previousStatus);
        }
      }).finally(_=>{
        this.checkboxStatus  = arrList;
      });
    },
    exportUsers() {
      let filteredData = this.filter;
      this.$store
        .dispatch("users/exportUsers", filteredData)
        .then((res) => {
          this.$swal({
            icon: "success",
            // title: `<h4>Role Updated Successfully</h4>`,
            showConfirmButton: true,
            confirmButtonColor: "#E84185",
            allowOutsideClick: true,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true,
            });
          }
        });
    },
    openInvitation() {
      this.userInvitationModal = true;
    },
    // bulkInvitation(){
    //   this.$refs.roleForm.validate().then(success => {
    //     if (success) {
    //       const payload = this.form
    //         this.$store.dispatch( 'users/assignRole' , payload ).then(res=>{
    //           this.$router.push({name:'users'})
    //         }).catch(error => {
    //           if (error.response) {
    //             this.$swal({
    //               icon: 'error',
    //               title: `<h4>${error.response.data.error}</h4>`,
    //               showConfirmButton: true,
    //               confirmButtonColor: '#E84185',
    //               allowOutsideClick: true
    //             });
    //           }
    //       });
    //     }
    //   });
    // }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
